import React, {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getToken} from "./store/local/store";
import {checkAccount, logoutAccount} from "./store/redux/reducers/account/accountActions";
import AppRouter from "./components/Router/Router";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";


function App() {
    const dispatch = useDispatch()
    const {loading} = useSelector(state => state.account)

    useEffect(() => {
        const token = getToken()

        if (token) {
            dispatch(checkAccount())
        } else {
            dispatch(logoutAccount())
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) {
        return <h1>Loading...</h1>
    }

    return (
        <div className="app">
            <BrowserRouter>
                <ScrollToTop>
                    <AppRouter/>
                </ScrollToTop>
            </BrowserRouter>
        </div>
    );
}

export default App;
