import {AccountTypes, initState} from "./accountReducerType"

export const accountReducer = (state = initState, action) => {
  switch (action.type) {
      case AccountTypes.CHECK_USER:
          return {...state, loading: true, error: null}
      case AccountTypes.ERROR_USER:
          return {...state, loading: false, error: null}
      case AccountTypes.SUCCESS_USER:
          return {...action.payload, loading: false, error: null}
      case AccountTypes.AUTH_LOGOUT:
          return {...initState, loading: false}
      case AccountTypes.UPDATE:
          return {...Object.assign(state, action.payload), loading: false, error: null}
      default:
          return state
  }
}
