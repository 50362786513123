import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {scroll} from "../../utils/scroll";

const ScrollToTop = ({children}) => {
    const location = useLocation()

    useEffect(() => {
        if (location.state && location.state.scroll) {
            return scroll(location.state.scroll)
        }
        window.scrollTo(0, 0);
    }, [location]);

    return <>{children}</>
};

export default ScrollToTop;
