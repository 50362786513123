import axios from 'axios'
import {getToken} from "../store/local/store";
import {app} from "../config";

const api = axios.create({
    // withCredentials: true,
    baseURL: app.api,
})

api.interceptors.request.use(function (config) {
    const token = getToken()
    if (token) {
        config.headers.Authorization = `Token ${token}`
    }

    return config
})

export {api}
