const app = {
    name: 'beonpay',
    env: process.env.REACT_APP_ENV,
    api: process.env.REACT_APP_BACK_URL,
    static: process.env.REACT_APP_STATIC_URL,
    infoEmail: process.env.REACT_APP_INFO_EMAIL,
}

if (app.env === 'local') {
    console.log('application config settings: ', app)
}

export {app}
