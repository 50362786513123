import React from "react";
import {
    DASHBOARD_ROUTE,
    HOME_ROUTE,
    INVOICE_DETAIL_ROUTE,
    INVOICE_ROUTE,
    LOGIN_ROUTE,
    PAYOUT_ROUTE,
    SETTINGS_ROUTE,
    PAYOUT_BATCH_ROUTE,
    PAYOUT_BATCH_DETAIL_ROUTE,
    PAYOUT_BATCH_CREATE_ROUTE,
} from "./links";

const Home = React.lazy(() => import("../../pages/Home/Home")
    .then(({default : Home}) => ({default: Home})));
const Dashboard = React.lazy(() => import("../../pages/Dashboard/Dashboard")
    .then(({default : Dashboard}) => ({default: Dashboard})));
const Login = React.lazy(() => import("../../pages/Login/Login")
    .then(({default : Login}) => ({default: Login})));
const Invoice = React.lazy(() => import("../../pages/Invoice/Invoice")
    .then(({default : Invoice}) => ({default: Invoice})));
const InvoiceDetail = React.lazy(() => import("../../pages/InvoiceDetail/InvoiceDetail")
    .then(({default : InvoiceDetail}) => ({default: InvoiceDetail})));
const Settings = React.lazy(() => import("../../pages/Settings/Settings")
    .then(({default : Settings}) => ({default: Settings})));
const Payout = React.lazy(() => import("../../pages/Payout/Payout")
    .then(({default : Payout}) => ({default: Payout})));
const PayoutBatchList = React.lazy(() => import("../../pages/PayoutBatchList/PayoutBatchList")
    .then(({default : PayoutBatchList}) => ({default: PayoutBatchList})));
const PayoutBatchCreate = React.lazy(() => import("../../pages/PayoutBatchCreate/PayoutBatchCreate")
    .then(({default : PayoutBatchCreate}) => ({default: PayoutBatchCreate})));
const PayoutBatchDetail = React.lazy(() => import("../../pages/PayoutBatchDetail/PayoutBatchDetail")
    .then(({default : PayoutBatchDetail}) => ({default: PayoutBatchDetail})));

const addRoute = (path, component) => {
    return {
        path: path,
        Component: component,
    }
}

export const publicRoutes = [
    addRoute(HOME_ROUTE, Home),
    addRoute(LOGIN_ROUTE, Login),
    // addRoute(LEGAL_ROUTE, Legal)
]

export const authRoutes = [
    addRoute(DASHBOARD_ROUTE, Dashboard),
    addRoute(INVOICE_ROUTE, Invoice),
    addRoute(SETTINGS_ROUTE, Settings),
    addRoute(PAYOUT_ROUTE, Payout),
    addRoute(PAYOUT_BATCH_ROUTE, PayoutBatchList),
    addRoute(PAYOUT_BATCH_CREATE_ROUTE, PayoutBatchCreate),
    addRoute(PAYOUT_BATCH_DETAIL_ROUTE, PayoutBatchDetail),

    // must be last, or fix pattern /dashboard/:type/:id
    addRoute(INVOICE_DETAIL_ROUTE, InvoiceDetail),
]
