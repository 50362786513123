export const AccountTypes = {
    CHECK_USER: 'ACCOUNT/CHECK',
    ERROR_USER: 'ACCOUNT/CHECK/ERROR',
    SUCCESS_USER: 'ACCOUNT/CHECK/SUCCESS',
    AUTH_LOGOUT: 'ACCOUNT/AUTH/LOGOUT',
    UPDATE: 'ACCOUNT/UPDATE'
}

export const initState = {
    loading: true,
    email: null,
    merchant_name: null,
    error: null,
    currency: 'EUR'
}
