/** Be On Pay Token */
const token = 'bopt'
/** Registration Request Is Send **/
const registration = 'rris'

export const getToken = () => {
    return localStorage.getItem(token)
}

export const setToken = (value) => {
    return localStorage.setItem(token, value)
}

export const deleteToken = () => {
    return localStorage.removeItem(token)
}

export const isRequestedRegistration = () => {
    return !!localStorage.getItem(registration)
}

export const setRequestedRegistration = () => {
    return localStorage.setItem(registration, 'isSet')
}
