import {AccountTypes} from "./accountReducerType";
import {apiGetCheckToken} from "../../../../api/account";
import {deleteToken} from "../../../local/store";

export const checkAccount = () => {
    return async (dispatch) => {
        try {
            dispatch({type: AccountTypes.CHECK_USER})
            const response = await apiGetCheckToken()
            dispatch({type: AccountTypes.SUCCESS_USER, payload: response.data})
        } catch (e) {
            deleteToken()
            dispatch({type: AccountTypes.ERROR_USER})
        }
    }
}

export const logoutAccount = () => {
    return (dispatch) => {
        deleteToken()
        dispatch({type: AccountTypes.AUTH_LOGOUT})
    }
}

export const updateAccount = (values) => {
    return (dispatch) => {
        dispatch({type: AccountTypes.UPDATE, payload: values})
    }
}
