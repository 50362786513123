import {api} from './index'

export const apiPostLogin = async (data) => {
    return await api.post('/account/login', data)
}

export const apiGetCheckToken = async () => {
    return await api.get('/account/check')
}

export const apiUpdateUser = async (data) => {
    return await api.post('/account/update', data)
}

export const apiPostRegistrationRequest = async (data) => {
    return await api.post('/account/register', data)
}
