// OPEN
export const HOME_ROUTE = '/'
export const LOGIN_ROUTE = '/login'
export const LEGAL_ROUTE = '/legal'

// AUTH
export const DASHBOARD_ROUTE = '/dashboard'
export const INVOICE_ROUTE = '/dashboard/invoice'
export const SETTINGS_ROUTE = '/dashboard/settings'
export const PAYOUT_ROUTE = '/dashboard/payout'
export const PAYOUT_BATCH_ROUTE = '/dashboard/payout/batch'
export const PAYOUT_BATCH_CREATE_ROUTE = '/dashboard/payout/batch/create/'
export const PAYOUT_BATCH_DETAIL_ROUTE = '/dashboard/payout/batch/:id/'
export const INVOICE_DETAIL_ROUTE = '/dashboard/:type/:id'
