import React, {Suspense} from 'react';
import {useSelector} from "react-redux";
import {Switch, Route, Redirect} from "react-router-dom";
import {authRoutes, publicRoutes} from "./routes";
import {HOME_ROUTE} from "./links";

function AppRouter() {
    const {email} = useSelector(state => state.account)

    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    {email && authRoutes.map(({path, Component}) =>
                        <Route key={path} path={path} component={Component} exact/>
                    )}
                    {publicRoutes.map(({path, Component}) =>
                        <Route key={path} path={path} component={Component} exact/>
                    )}
                    <Redirect to={HOME_ROUTE}/>
                </Switch>
            </Suspense>
        </>
    );
}

export default AppRouter
